import React, { useState } from "react"
import { nanoid } from "nanoid"

import { CardProject } from "../card/card"
import { CardSlider } from "../card-slider/card-slider"

export const GliderVirtualLink = ({ data, id }) => {
  const [unique] = useState(nanoid)
  
  return (
    <div className="px-0 px-lg-3">
      <CardSlider
        id={`${id}`}
        showInitial={3}
        arrowsMdDown={true}
        arrowsSmDown={true}
        noGutter
      >
        {data.map((item, index) => {
          return (
            <CardProject
              key={`${item.propertyName}-${unique}-${index}`}
              title={item.propertyName || item.title}
              description={
                `${
                  item.description ? item.description.substring(0, 170) : ""
                }` ||
                `${item.excerpt ? item.excerpt.substring(0, 170) : ""}` ||
                "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Provident deserunt laborum nostrum."
              }
              img={
                item.image ||
                (item.featuredImage && item.featuredImage.sourceUrl) ||
                "https://ap-bismite-yk88.antikode.dev/app/uploads/2020/03/img_development-main-2.jpg"
              }
              category={
                item.type ||
                (item.propertyTypes &&
                  item.propertyTypes.nodes[0] &&
                  item.propertyTypes.nodes[0].name) ||
                null
              }
              location={item.location || null}
              link={
                item.linkTo ||
                `/development/${item.propertyTypes && item.propertyTypes.nodes[0] &&
                  item.propertyTypes.nodes[0].slug}/${item.slug}` ||
                "/"
              }
              target={item.target && item.target}
              className="px-sm-down-0 col ratio-1-1"
            />
          )
        })}
      </CardSlider>
    </div>
  )
}