import React, { useEffect, useLayoutEffect, memo, useContext } from "react"
import ReactMarkdown from "react-markdown"
import gql from "graphql-tag"
import { useMediaPredicate } from "react-media-hook"

import { useStaticQuery, graphql, withAssetPrefix } from "gatsby"
import { useLazyQuery } from "@apollo/react-hooks"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { LangContext } from "../context/LangContext"

import { SocialShare } from "../components/anti/social-links/social-links"
import { Button, ButtonGroup } from "../components/anti/buttons/buttons"
import { CoverContent, Cover } from "../components/anti/cover/cover"
import { AcfCoverMain } from "../components/gutenberg/acf-cover-main"
import { Card, CardProject } from "../components/anti/card/card"
import { CardSlider } from "../components/anti/card-slider/card-slider"
import { Feature } from "../components/anti/feature/feature"
import { Map, GoogleMapsEmbed } from "../components/anti/map/map"
import { Container, Section } from "../components/anti/grid/grid"
import { NavBottom } from "../components/anti/nav-bottom/nav-bottom"
import { HeadingLink } from "../components/anti/type/type"
import { LeadForm } from "../components/anti/lead-form/lead-form"
import { CalculatorFinance } from "../components/anti/calculator-finance/calculator-finance"
import { GliderVirtualLink } from "../components/anti/glider/glider"

import { replaceUnicode } from "../components/anti/utils/utils"
import linkIconShare from "../assets/img/icons/link_2x.png"

import "@glidejs/glide/dist/css/glide.core.min.css"
import "@glidejs/glide/dist/css/glide.theme.min.css"

const buttonScrollHandler = (target) => {
  var scrollContainer = target
  do { //find scroll container
    scrollContainer = scrollContainer.parentNode
    if (!scrollContainer) return
    scrollContainer.scrollTop += 1
  } while (scrollContainer.scrollTop == 0)

  var targetY = -60
  do { //find the top of target relatively to the container
    if (target == scrollContainer) break
    targetY += target.offsetTop
  } while (target = target.offsetParent)

  const scroll = function(c, a, b, i) {
    i++; if (i > 30) return
    c.scrollTop = a + (b - a) / 30 * i
    setTimeout(function(){ scroll(c, a, b, i); }, 20)
  }
  // start scrolling
  scroll(scrollContainer, scrollContainer.scrollTop, targetY, 0)
}

const Shortcodes = ({ data, nearbyData, eBrochure, name, lang, eCatalogUrl, propertyTypesProject, projectData, contentIds, thisUrl }) => {
  const nearbyProjects = nearbyData && nearbyData.projects.nodes
  const eBrochureProject =
    eBrochure !== undefined &&
    eBrochure.eBrochureBy !== null &&
    eBrochure.eBrochureBy.eBrochure.file.mediaItemUrl

  if (data.attributes.text === "nearby") {
    return nearbyData ? (
      <Section>
        <Container>
          <h2 className="mb-3">Nearby</h2>
        </Container>
        <Container className="px-sm-down-0">
          <CardSlider
            showInitial={3}
            showLgDown={1}
            showMdDown={1}
            showSmDown={1}
            arrowsInitial={true}
            arrowsLgDown={true}
            arrowsMdDown={true}
            arrowsSmDown={true}
          >
            {nearbyProjects &&
              nearbyProjects.map((project, i) => {
                const colHeadline = project.blocks.find(
                  x => x.name === "acf/col-headline"
                )
                const description = colHeadline && colHeadline.colHeadline.text
                const propertyTypes = project.propertyTypes.nodes[0].name
                return (
                  <CardProject
                    title={project.title}
                    description={description}
                    img={
                      project.featuredImage && project.featuredImage.sourceUrl
                    }
                    category={propertyTypes}
                    className="ratio-1-1"
                    link={`/development/${project.propertyTypes.nodes[0].slug}/${project.slug}`}
                  />
                )
              })}
          </CardSlider>
        </Container>
      </Section>
    ) : (
      <div></div>
    )
  } else if (data.attributes.text === "calculator-finance") {
    return <div id="fin-pro"><CalculatorFinance key={`calculator-finance`} /></div>
  } else if (data.attributes.text === "lead-form") {
    return <LeadForm name={name} projectData={projectData} propertyTypesProject={propertyTypesProject} contentIds={contentIds} thisUrleCatalog={thisUrl}/>
  } else if (data.attributes.text === "e-brochure") {
    if (eBrochureProject) {
      return (
        <Section>
          <Container>
            <div className="row">
              <div className="col-md-6 col-12 mb-3">
                <h5>
                  {lang === "EN"
                    ? "Let's calculate the estimated cost of your dream property."
                    : "Ayo hitung perkiraan biaya properti impian Anda."}
                </h5>
              </div>
              <div className="col-md-6 col-12 d-flex justify-content-center align-items-center">
                <Button
                  variant="secondary btn-p-all"
                  onClick={() => buttonScrollHandler(document.getElementById('fin-pro'))}
                >
                  {lang === "EN" ? "Calculate here" : "Hitung disini"}{" "}
                  <i className="fas fa-arrow-right ml-1"></i>
                </Button>
                {/* <Button variant="secondary btn-p-all" 
                  // link={eBrochureProject}
                  onClick={() => {
                    window.dataLayer = window.dataLayer || []
                    window.dataLayer.push({
                      event: "DownloadBrochure",
                      page_url: window.location.href,
                      page_title: name,
                      content_type: "home_listing",
                      content_ids: contentIds,
                      city: "bsd-city",
                      region: "bsd-city",
                      country: "Indonesia",
                    })
                    window.open(eBrochureProject, "_blank")
                  }}
                >
                  {lang === "EN" ? "e-Brochure" : "e-Brosur"}{" "}
                  <i className="fas fa-arrow-right ml-1"></i>
                </Button> */}
                &nbsp;&nbsp;&nbsp;
                {/* <a
                  href={eCatalogUrl}
                  target={eCatalogUrl !== "#" ? "_blank" : "_self"}
                  className="btn btn-secondary btn-p-all"
                  rel="noopener noreferrer"
                >
                  e-Catalog&nbsp;
                  <i className="fas fa-arrow-right ml-1"></i>
                </a> */}
              </div>
            </div>
          </Container>
        </Section>
      )
    } else {
      return (
        <Section>
          <Container>
            <div className="row">
              <div className="col-md-6 col-12 mb-3">
                <h5>
                  {lang === "EN"
                    ? "Let's calculate the estimated cost of your dream property."
                    : "Ayo hitung perkiraan biaya properti impian Anda."}
                </h5>
              </div>
              <div className="col-md-6 col-12 d-flex justify-content-center align-items-center">
                <Button
                  variant="secondary btn-p-all"
                  onClick={() => buttonScrollHandler(document.getElementById('fin-pro'))}
                >
                  {lang === "EN" ? "Calculate here" : "Hitung disini"}{" "}
                  <i className="fas fa-arrow-right ml-1"></i>
                </Button>
                {/* <Button variant="secondary btn-p-all" link="#">
                  {lang === "EN" ? "e-Brochure" : "e-Brosur"}{" "}
                  <i className="fas fa-arrow-right ml-1"></i>
                </Button> */}
                &nbsp;&nbsp;&nbsp;
                {/* <Button
                  className="btn btn-secondary btn-p-all"
                  onClick={eCatalogUrl}
                >
                  e-Catalog
                </Button> */}
              </div>
            </div>
          </Container>
        </Section>
      )
    }
  }
  return data.attributes.text
}

const Blocks = memo(
  ({ wp, nearbyData, iconList, props, eBrochure, propertyTypesActive }) => {
    return wp.blocks.map((block, i) => {
      // propertyTypesActive === "residential" && (
      //   <CalculatorFinance key={`calculator-finance`} />
      // )
      switch (block.name) {
        case "acf/cover-main":
          return (
            <AcfCoverMain
              data={block}
              key={`${block.name}${i}`}
              breadcrumb={props.location.pathname}
            />
          )

        case "acf/col-headline":
          return (
            <Section className="bg-light">
              <Container className="">
                <div className="row">
                  <div className="col-lg-6 col-xl-5 pr-4">
                    <h2 className="h2">
                      {block.colHeadline &&
                        block.colHeadline.title &&
                        replaceUnicode(block.colHeadline.title)}
                    </h2>
                  </div>
                  <div className="col-lg-6 col-xl-7">
                    <p>
                      {
                        // block.colHeadline && block.colHeadline.text
                        <ReactMarkdown
                          source={block.colHeadline.text}
                          escapeHtml={false}
                        />
                      }
                    </p>
                    {/* On FUTURE DEVELOPMENT details pages only */}
                    {block.colHeadline.additionalInfo != null ? (
                      <>
                        <div className="mt-5">
                          <p class="h4 mb-3 text-gray-600">
                            {block.colHeadline.additionalInfo[0].label}
                          </p>
                          <p>{block.colHeadline.additionalInfo[0].value}</p>
                        </div>
                        {block.colHeadline.buttons != null ? (
                          <Button
                            variant="link"
                            to={block.colHeadline.buttons[0].url}
                          >
                            {block.colHeadline.buttons[0].text}
                          </Button>
                        ) : null}
                      </>
                    ) : null}
                  </div>
                </div>
              </Container>
            </Section>
          )

        case "acf/image-slider":
          if (block.imageSlider && block.imageSlider.images !== null) {
            return (
              <Section>
                <Container>
                  <HeadingLink>
                    <h2 className="h3">
                      {block.imageSlider && block.imageSlider.title}
                    </h2>
                    <Button variant="link">
                      {block.imageSlider && block.imageSlider.link.text}
                    </Button>
                  </HeadingLink>
                  <CardSlider
                    showInitial={6}
                    showLgDown={5}
                    showMdDown={4}
                    showSmDown={3.5}
                  >
                    {block.imageSlider &&
                      block.imageSlider.images.length > 0 &&
                      block.imageSlider.images.map((item, i) => {
                        return (
                          <Card
                            variant="boxless"
                            img={item.img && item.img.mediaItemUrl}
                            imgHeight="h-ratio-1-1"
                          />
                        )
                      })}
                  </CardSlider>
                </Container>
              </Section>
            )
          }

        case "acf/acfgallery":
          return (
            <Section
              className={`${block.acfBlockGallery &&
                block.acfBlockGallery.bgColor} ${block.attributes &&
                block.attributes.className}`}
            >
              <Container className="px-sm-down-0">
                <CardSlider
                  // fade
                  noGutter
                  showInitial={1}
                  showLgDown={1}
                  showMdDown={1}
                  showSmDown={1}
                  visibleInitial={false}
                  visibleLgDown={false}
                  arrowsLgDown={true}
                  arrowsMdDown={true}
                  arrowsSmDown={true}
                >
                  {block.acfBlockGallery &&
                    block.acfBlockGallery.images &&
                    block.acfBlockGallery.images.map((item, i) => {
                      return (
                        <Card
                          variant="boxless"
                          img={item.mediaItemUrl}
                          imgHeight="h-ratio-2-1"
                          key={i}
                        />
                      )
                    })}
                </CardSlider>
              </Container>
            </Section>
          )

        case "acf/map-with-features":
          return (
            <>
              <Section className="map-w-features p-0">
                <div className="row row-0">
                  <div className="col-md-7">
                    {wp?.embedProjectsLocation?.embedUrl && (
                      <GoogleMapsEmbed
                        embedUrl={wp?.embedProjectsLocation?.embedUrl}
                      />
                    )}
                    {/* {block?.MapWFeatures?.location?.longitude &&
                      block?.MapWFeatures?.location?.latitude && (
                        <Map
                          long={
                            block?.MapWFeatures?.location &&
                            parseFloat(block?.MapWFeatures?.location?.longitude)
                          }
                          lat={
                            block?.MapWFeatures?.location &&
                            parseFloat(block?.MapWFeatures?.location?.latitude)
                          }
                          zoom={16}
                        />
                      )} */}
                  </div>
                  <div className="col-md-5 py-main-sm px-md-4 bg-secondary">
                    <Container className="features-top">
                      <h2 className="h2">
                        {block.MapWFeatures && block.MapWFeatures.title}
                      </h2>
                      <ul>
                        {block.MapWFeatures.featuresTop &&
                          block.MapWFeatures.featuresTop.map((item, i) => {
                            return (
                              <li key={i}>
                                <Feature title={item.name} text={item.text} />
                              </li>
                            )
                          })}
                      </ul>
                    </Container>
                  </div>
                </div>
              </Section>
              <Section>
                <Container className="features-bottom">
                  <div className="row row-3">
                    {block.MapWFeatures.featuresBottom &&
                      block.MapWFeatures.featuresBottom.length > 0 &&
                      block.MapWFeatures.featuresBottom.map((item, i) => {
                        return (
                          <div className="col-md-6" key={i}>
                            <Feature title={item.name} text={item.text} />
                          </div>
                        )
                      })}
                  </div>
                </Container>
              </Section>
            </>
          )

        case "acf/iconlist":
          const iconListBlock = iconList?.project?.blocks.find(
            item => item.name === "acf/iconlist"
          )
          return (
            <Section
              className={`${block.acfBlockIconList &&
                block.acfBlockIconList.bgColor} ${block.acfBlockIconList &&
                block.attributes.className}`}
            >
              <Container>
                <div className="mb-4">
                  <h2 className="h2">
                    {block.acfBlockIconList && block.acfBlockIconList.title}
                  </h2>
                  <p className="mb-0">
                    {block.acfBlockIconList && block.acfBlockIconList.text}
                  </p>
                </div>
                <div className="row mb-n2">
                  {iconListBlock?.acfBlockIconList?.list &&
                    iconListBlock?.acfBlockIconList?.list.length > 0 &&
                    iconListBlock?.acfBlockIconList?.list.map((item, i) => {
                      return (
                        <div className="col-12 col-md-4 col-lg">
                          <Feature
                            img={item.icon && item.icon.mediaItemUrl}
                            title={item.title && item.title}
                            titleClassName="mb-2 mt-sm-down-1"
                            text={item.description && item.description}
                            key={i}
                          />
                        </div>
                      )
                    })}
                </div>
              </Container>
            </Section>
          )

          {
            /* case "acf/instagram":
        return (
          <Instagram
            title={block.instagram.title}
            bgColor={block.instagram.bgColor}
            className={block.attributes.className}
          />
        ); */
          }

        case "acf/cover-content":
          return (
            <>
              {/* <Section>
            <Container>
              <h2 className="h3">Nearby</h2>
              <CardSlider showInitial={3} showLgDown={3} showMdDown={2}>
                <Card
                  variant="overlay"
                  title="AEON Mall"
                  titleClassName="h5"
                  text="Shop & Dine"
                  img={data.imgCover.childImageSharp.fluid}
                  imgHeight="h-ratio-1-1"
                />
                <Card
                  variant="overlay"
                  title="BSD Green Office"
                  titleClassName="h5"
                  text="Office"
                  img={data.imgCover.childImageSharp.fluid}
                  imgHeight="h-ratio-1-1"
                />
                <Card
                  variant="overlay"
                  title="The Breeze"
                  titleClassName="h5"
                  text="Shop & Dine"
                  img={data.imgCover.childImageSharp.fluid}
                  imgHeight="h-ratio-1-1"
                />
                <Card
                  variant="overlay"
                  title="AEON Mall"
                  titleClassName="h5"
                  text="Shop & Dine"
                  img={data.imgCover.childImageSharp.fluid}
                  imgHeight="h-ratio-1-1"
                />
                <Card
                  variant="overlay"
                  title="AEON Mall"
                  titleClassName="h5"
                  text="Shop & Dine"
                  img={data.imgCover.childImageSharp.fluid}
                  imgHeight="h-ratio-1-1"
                />
                <Card
                  variant="overlay"
                  title="AEON Mall"
                  text="Shop & Dine"
                  img={data.imgCover.childImageSharp.fluid}
                  imgHeight="h-ratio-1-1"
                />
              </CardSlider>
            </Container>
          </Section> */}
              {propertyTypesActive === "residential" && (
                <div id="fin-pro"><CalculatorFinance key={`calculator-finance`} /></div>
              )}
              <CoverContent
                theme="dark"
                img={
                  block.coverContent &&
                  block.coverContent.img &&
                  block.coverContent.img.sourceUrl
                }
                imgOverlay="20"
                className={`mb-n2 ${block.coverContent.bgColor || ""} ${block
                  .attributes.className || ""}`}
              >
                <h2 className="h2">{block.coverContent.title || ""}</h2>
                <p>{block.coverContent.text || ""}</p>
                {block.coverContent.buttons && (
                  <ButtonGroup>
                    {block.coverContent.buttons.length > 0 &&
                      block.coverContent.buttons.map((item, i) => {
                        return (
                          <Button
                            variant="link"
                            link={item.link && item.link.url}
                            target={item.link && item.link.target}
                            key={i}
                          >
                            {item.text}
                          </Button>
                        )
                      })}
                  </ButtonGroup>
                )}
              </CoverContent>
            </>
          )

        case "core/shortcode":
          return (
            <Shortcodes
              data={block}
              nearbyData={nearbyData}
              eBrochure={eBrochure}
              name={wp.title}
              key={`shortcode${i}`}
              propertyTypesActive={propertyTypesActive}
            />
          )
      }
    })
  }
)

const DevelopmentDetails = ({location, pageContext, pathContext}) => {
  const { lang, setLang } = useContext(LangContext)
  const data = useStaticQuery(graphql`
    query StaticData {
      imgCover: file(relativePath: { eq: "sample/bg_rectangle-dark-2.jpg" }) {
        id
        name
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      wordPress {
        propertyTypes {
          nodes {
            name
            slug
            projects {
              nodes {
                title
                slug
                blocks {
                  ... on WordPress_AcfCoverMainBlock {
                    name
                    attributes {
                      className
                    }
                    coverMain {
                      img {
                        mediaItemUrl
                      }
                      imgOverlay
                      label
                      title
                      text
                      buttons {
                        text
                        link {
                          target
                          url
                        }
                      }
                    }
                  }
                }
                seo {
                  title
                  metaDesc
                  twitterDescription
                  opengraphDescription
                }
              }
            }
          }
        }
      }
    }
  `)

  const GET_NEARBY_PROJECT = gql`
    query NearbyProjects($id: [ID]) {
      projects(where: { in: $id }) {
        nodes {
          title
          slug
          featuredImage {
            id
            title
            sourceUrl
            caption
          }
          propertyTypes {
            nodes {
              name
              slug
            }
          }
          blocks {
            ... on AcfCoverMainBlock {
              name
              attributes {
                className
              }
              coverMain {
                img {
                  mediaItemUrl
                }
                imgOverlay
                label
                title
                text
                buttons {
                  text
                  link {
                    target
                    url
                  }
                }
              }
            }
            ... on AcfColHeadlineBlock {
              name
              attributes {
                className
              }
              colHeadline {
                bgColor
                title
                text
                additionalInfo {
                  label
                  value
                }
                buttons {
                  text
                  link {
                    target
                    url
                  }
                }
                list {
                  text
                  icon {
                    sourceUrl
                  }
                }
              }
            }
          }
        }
      }
    }
  `

  const GET_BLOCKS = gql`
    query GetBlocks($id: ID!) {
      project(id: $id, idType: URI) {
        slug
        blocks {
          name
          ... on AcfIconlistBlock {
            name
            attributes {
              className
            }
            acfBlockIconList {
              bgColor
              title
              text
              list {
                icon {
                  mediaItemUrl
                }
                title
                description
                link {
                  url
                }
              }
            }
          }
        }
      }
    }
  `
  const GET_EBROCHURE = gql`
    query brochure($id: String) {
      eBrochureBy(uri: $id) {
        eBrochure {
          file {
            mediaItemUrl
          }
        }
      }
    }
  `

  const wp = pageContext.data
  const wpID =
    pageContext.data.translation &&
    pageContext.data.translation.blocks
  const slug = pathContext.data.slug
  const contentIds = slug.replace(/-/g, "_")
  const propertyTypesName = pageContext.propertyTypesName
  const resizeScreen = useMediaPredicate("(max-width: 992px)")

  const [getNearby, { data: nearbyData }] = useLazyQuery(GET_NEARBY_PROJECT)
  const [getIconList, { data: iconList }] = useLazyQuery(GET_BLOCKS)

  // Modify navbar style
  useLayoutEffect(() => {
    const navbar = document.querySelector(".navbar")
    navbar.classList.remove("transparent")
    navbar.classList.replace("dark", "light")
  }, [])

  useEffect(() => {
    if (wp.nearby !== undefined && wp.nearby.length > 0) {
      getNearby({ variables: { id: wp.nearby } })
    }
    // if (wp.nearby.length > 0) {
    //   getNearby({ variables: { id: wp.nearby } })
    // }

    const iconListExists = wp.blocks.find(item => item.name === "acf/iconlist")
    if (iconListExists) {
      getIconList({
        variables: {
          id: pageContext.data.slug,
        },
      })
    }
  }, [])

  const currentType =
    wp.propertyTypes.nodes[0] &&
    wp.propertyTypes.nodes[0].slug &&
    wp.propertyTypes.nodes[0].slug
  const propertyTypes = data.wordPress.propertyTypes.nodes
  const projectsByType =
    propertyTypes.find(x => x.slug === currentType) &&
    propertyTypes.find(x => x.slug === currentType).projects.nodes
  const currentIndex =
    currentType && projectsByType.findIndex(x => x.slug === wp.slug)

  const thisBlocks = 
    lang === "EN" 
      ? pageContext?.data?.blocks
      : pageContext?.data?.translation?.blocks

  let listBlocks = []

  thisBlocks?.map(item => {
    listBlocks.push(item.name)
  })

  const eCatalogProject = wp?.ecatalogUrl?.ecatalogUrl

  const handleCopy = () => {
    const thisInput = document.createElement("INPUT")

    document.body.append(thisInput)
    thisInput.value = location.href
    thisInput.select()
    document.execCommand("copy")
    thisInput.remove()
    setTimeout(function(){document.getElementById('copyButton').innerHTML = `<img src=${linkIconShare} /> <span class="tooltiptext">${lang === "EN" ? "URL has copied" : "Tautan telah disalin"}</span>`;}, 200)
    setTimeout(function(){document.getElementById('copyButton').innerHTML = `<img src=${linkIconShare} />`;}, 2000)
  }
  
  useEffect(() => {
    if (listBlocks.includes("core/embed")){
      if (resizeScreen){
        document.querySelector(".cover-full").style.marginTop = "0px"
        document.querySelector(".py-main.bg-white").style.paddingBottom = "5px"
        document.querySelector(".cover-bg-wrapper").style.overflow = "unset"
      }
      // }else{
      //   // document.querySelector(".cover-full").style.marginTop = "0px"
      //   // document.querySelector(".py-main.bg-white").style.paddingBottom = "5px"
      // }
    }
  }, [])

  useEffect(() => {
    productDetailDataLayer()
  }, [])
    

  const [getBrochure, { data: eBrochure }] = useLazyQuery(GET_EBROCHURE)

  useEffect(() => {
    getBrochure({
      variables: {
        id: slug,
      },
    })
  }, [])

  const nextProject = () => {
    if (projectsByType[currentIndex + 1] !== undefined) {
      return projectsByType[currentIndex + 1]
    } else {
      return projectsByType[projectsByType[0]]
    }
  }

  const prevProject = () => {
    if (projectsByType[currentIndex - 1] !== undefined) {
      return projectsByType[currentIndex - 1]
    } else {
      return projectsByType[projectsByType.length - 1]
    }
  }

  const changeNullToBeZero = val => {
    if (val == null) return 0
    return val
  }

  const setDataLayerValue = event => {
    const value = {
      event: event,
      page_url: typeof window !== "undefined" ? window.location.href : "",
      page_title: wp?.title,
      content_type: "home_listing",
      content_ids: contentIds,
      city: "bsd-city",
      region: "bsd-city",
      country: "Indonesia",
      lease_start_date: "2021-01-01",
      lease_end_date: "2100-01-01",
      currency: "IDR",
      preferred_price_range: [
        wp?.projectCustomfields?.preferredPriceRangeFrom,
        wp?.projectCustomfields?.preferredPriceRangeTo,
      ],
      preferred_baths_range: [
        changeNullToBeZero(
          wp?.projectCustomfields?.preferredBathsRangeFrom
        ),
        changeNullToBeZero(wp?.projectCustomfields?.preferredBathsRangeTo),
      ],
      preferred_bed_range: [
        changeNullToBeZero(wp?.projectCustomfields?.preferredBedRangeFrom),
        changeNullToBeZero(wp?.projectCustomfields?.preferredBedRangeTo),
      ],
      property_type: propertyTypesName,
    }
    return value
  }

  const setDetailWFHDataLayer = value => {
    if (typeof window !== "undefined") {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push(value)
    }
  }

  const productDetailDataLayer = () => {
    setDetailWFHDataLayer(setDataLayerValue("ViewContent"))
  }

  const { seo } = lang === "EN" ? wp : wp.translation || wp
  const SEODefaultImg =
    lang === "EN"
      ? wp?.blocks[0]?.coverMain?.img?.mediaItemUrl
      : wp?.translation?.blocks[0]?.coverMain?.img?.mediaItemUrl ||
        wp?.blocks[0]?.coverMain?.img?.mediaItemUrl
  const featuredImage = 
    lang === "EN" 
      ? wp?.featuredImage?.sourceUrl : wp?.translation?.featuredImage?.sourceUrl
      || wp?.featuredImage?.sourceUrl
  const thisUrl = process.env.BASE_URL + location.pathname

  const schemaDetailProjects = [
    {
      "@context": "https://schema.org/",
      "@type": "Product",
      "name": wp?.title,
      "description": seo?.metaDesc,
      "review": {
        "@type": "Review",
        "reviewRating": {
          "@type": "Rating",
          "ratingValue": "5",
          "bestRating": "5"
        },
        "author": {
          "@type": "Organization",
          "name": "Sinar Mas Land"
        }
      }
    }
  ]

  return (
    <Layout isMicrodataProduct={true}>
      <SEO
        title={seo?.title}
        desc={seo?.metaDesc}
        fbImg={featuredImage}
        fbTitle={seo?.opengraphTitle || seo?.title}
        fbDesc={seo?.opengraphDescription}
        url={thisUrl}
        twitterTitle={seo?.twitterTitle || seo?.title}
        twitterDesc={seo?.twitterDescription}
        twitterImg={featuredImage}
        // schemaMarkup={schemaDetailProjects}
      />

      {lang === "ID" &&
        wpID &&
        wpID.map((block, i) => {
          switch (block.name) {
            case "core/embed":
              return (
                <Cover
                  type={{ name: "core-embed/youtube" }}
                  theme="dark"
                  imgHeight="h-400px h-md-500px w-100"
                  imgOverlay="40"
                  className="cover cover-full-top"
                  videoSrc={block.attributes.url}
                  // imgOverlay="30"
                  // imgHeight="h-500px h-md-600px w-100"
                  // className="cover-full cover-main"
                />
              )
            case "acf/cover-main":
              return (
                <>
                  <AcfCoverMain
                    data={block}
                    key={`${block.name}${i}`}
                    breadcrumb={location.pathname}
                  />
                  <section
                      className="py-main pt-3 pb-3 bg-light social-dev-wrapper"
                    >
                    <div className="container">
                      <div
                        className="row"
                      >
                        <div className="col-lg-12 col-xl-12">
                          <SocialShare link={thisUrl} onClick={handleCopy}/>
                        </div>
                      </div>
                    </div>
                  </section>
                  <div>
                    <meta itemProp="mpn" content="925872" />
                    <meta itemProp="name" content={seo?.title} />
                    <link itemProp="image" href={block?.coverMain?.img?.mediaItemUrl} />
                    <meta itemProp="description" content={seo?.opengraphDescription} />
                    <div itemProp="offers" itemType="https://schema.org/AggregateOffer" itemScope>
                      <meta itemProp="lowPrice" content="0" />
                      <meta itemProp="highPrice" content="0" />
                      <meta itemProp="offerCount" content="10" />
                      <meta itemProp="priceCurrency" content="IDR" />
                    </div>
                    <div itemProp="review" itemType="https://schema.org/Review" itemScope>
                      <div itemProp="author" itemType="https://schema.org/Person" itemScope>
                        <meta itemProp="name" content="Sinar Mas Land" />
                      </div>
                      <div itemProp="reviewRating" itemType="https://schema.org/Rating" itemScope>
                        <meta itemProp="ratingValue" content="4.5" />
                        <meta itemProp="bestRating" content="5" />
                      </div>
                    </div>
                    <meta itemProp="sku" content="0446310786" />
                    <div itemProp="brand" itemType="https://schema.org/Brand" itemScope>
                      <meta itemProp="name" content="BSD City" />
                    </div>
                  </div>
                </>
              )

            case "acf/col-headline":
              return (
                <Section className="bg-light">
                  <Container className="">
                    <div className="row">
                      <div className="col-lg-6 col-xl-5 pr-4">
                        <h2 className="h2">
                          {block.colHeadline &&
                            block.colHeadline.title &&
                            replaceUnicode(block.colHeadline.title)}
                        </h2>
                      </div>
                      <div className="col-lg-6 col-xl-7">
                        <p>
                          {
                            // block.colHeadline && block.colHeadline.text
                            <ReactMarkdown
                              source={block.colHeadline.text}
                              escapeHtml={false}
                            />
                          }
                        </p>
                        {/* On FUTURE DEVELOPMENT details pages only */}
                        {block.colHeadline.additionalInfo != null ? (
                          <>
                            <div className="mt-5">
                              <p class="h4 mb-3 text-gray-600">
                                {block.colHeadline.additionalInfo[0].label}
                              </p>
                              <p>{block.colHeadline.additionalInfo[0].value}</p>
                            </div>
                            {block.colHeadline.buttons != null ? (
                              <Button
                                variant="link"
                                to={block.colHeadline.buttons[0].url}
                              >
                                {block.colHeadline.buttons[0].text}
                              </Button>
                            ) : null}
                          </>
                        ) : null}
                      </div>
                    </div>
                  </Container>
                </Section>
              )

            case "acf/image-slider":
              if (block.imageSlider && block.imageSlider.images !== null) {
                return (
                  <Section>
                    <Container>
                      <HeadingLink>
                        <h2 className="h3">
                          {block.imageSlider && block.imageSlider.title}
                        </h2>
                        <Button variant="link">
                          {block.imageSlider && block.imageSlider.link.text}
                        </Button>
                      </HeadingLink>
                      <CardSlider
                        showInitial={6}
                        showLgDown={5}
                        showMdDown={4}
                        showSmDown={3.5}
                      >
                        {block.imageSlider &&
                          block.imageSlider.images.length > 0 &&
                          block.imageSlider.images.map((item, i) => {
                            return (
                              <Card
                                variant="boxless"
                                img={item.img && item.img.mediaItemUrl}
                                imgHeight="h-ratio-1-1"
                              />
                            )
                          })}
                      </CardSlider>
                    </Container>
                  </Section>
                )
              }

            case "acf/acfgallery":
              return (
                <Section
                  className={`${block.acfBlockGallery &&
                    block.acfBlockGallery.bgColor} ${block.attributes &&
                    block.attributes.className}`}
                >
                  <Container className="px-sm-down-0">
                    <CardSlider
                      // fade
                      noGutter
                      showInitial={1}
                      showLgDown={1}
                      showMdDown={1}
                      showSmDown={1}
                      visibleInitial={false}
                      visibleLgDown={false}
                      arrowsLgDown={true}
                      arrowsMdDown={true}
                      arrowsSmDown={true}
                    >
                      {block.acfBlockGallery &&
                        block.acfBlockGallery.images &&
                        block.acfBlockGallery.images.map((item, i) => {
                          return (
                            <Card
                              variant="boxless"
                              img={item.sourceUrl}
                              imgHeight="h-ratio-2-1"
                              key={i}
                            />
                          )
                        })}
                    </CardSlider>
                  </Container>
                </Section>
              )

            case "acf/map-with-features":
              return (
                <>
                  <Section className="map-w-features p-0">
                    <div className="row row-0">
                      <div className="col-md-7">
                        <GoogleMapsEmbed
                          embedUrl={wpID?.embedProjectsLocation?.embedUrl}
                        />
                        {/* <Map
                          long={
                            block.MapWFeatures.location &&
                            parseFloat(block.MapWFeatures.location.longitude)
                          }
                          lat={
                            block.MapWFeatures.location &&
                            parseFloat(block.MapWFeatures.location.latitude)
                          }
                          zoom={16}
                        /> */}
                      </div>
                      <div className="col-md-5 py-main-sm px-md-4 bg-secondary">
                        <Container className="features-top">
                          <h2 className="h2">
                            {block.MapWFeatures && block.MapWFeatures.title}
                          </h2>
                          <ul>
                            {block.MapWFeatures.featuresTop &&
                              block.MapWFeatures.featuresTop.map((item, i) => {
                                return (
                                  <li key={i}>
                                    <Feature
                                      title={item.name}
                                      text={item.text}
                                    />
                                  </li>
                                )
                              })}
                          </ul>
                        </Container>
                      </div>
                    </div>
                  </Section>
                  <Section>
                    <Container className="features-bottom">
                      <div className="row row-3">
                        {block.MapWFeatures.featuresBottom &&
                          block.MapWFeatures.featuresBottom.length > 0 &&
                          block.MapWFeatures.featuresBottom.map((item, i) => {
                            return (
                              <div className="col-md-6" key={i}>
                                <Feature title={item.name} text={item.text} />
                              </div>
                            )
                          })}
                      </div>
                    </Container>
                  </Section>
                </>
              )

            case "acf/iconlist":
              return (
                <Section
                  className={`${block.acfBlockIconList &&
                    block.acfBlockIconList.bgColor} ${block.acfBlockIconList &&
                    block.attributes.className}`}
                >
                  <Container>
                    <div className="mb-4">
                      <h2 className="h2">
                        {block.acfBlockIconList && block.acfBlockIconList.title}
                      </h2>
                      <p className="mb-0">
                        {block.acfBlockIconList && block.acfBlockIconList.text}
                      </p>
                    </div>
                    <div className="row mb-n2">
                      {block?.acfBlockIconList?.list &&
                        block?.acfBlockIconList?.list.length > 0 &&
                        block?.acfBlockIconList?.list.map((item, i) => {
                          return (
                            <div className="col-12 col-md-4 col-lg">
                              <Feature
                                img={item.icon && item.icon.mediaItemUrl}
                                title={item.title && item.title}
                                titleClassName="mb-2 mt-sm-down-1"
                                text={item.description && item.description}
                                key={i}
                              />
                            </div>
                          )
                        })}
                    </div>
                  </Container>
                </Section>
              )

              // case "acf/iconlist":
              //   const iconListBlock = iconList?.project?.blocks.find(
              //     item => item.name === "acf/iconlist"
              //   )
              //   return (
              //     <Section
              //       className={`${block.acfBlockIconList &&
              //         block.acfBlockIconList.bgColor} ${block.acfBlockIconList &&
              //         block.attributes.className}`}
              //     >
              //       <Container>
              //         <div className="mb-4">
              //           <h2 className="h2">
              //             {block.acfBlockIconList && block.acfBlockIconList.title}
              //           </h2>
              //           <p className="mb-0">
              //             {block.acfBlockIconList && block.acfBlockIconList.text}
              //           </p>
              //         </div>
              //         <div className="row mb-n2">
              //           {iconListBlock?.acfBlockIconList?.list &&
              //             iconListBlock?.acfBlockIconList?.list.length > 0 &&
              //             iconListBlock?.acfBlockIconList?.list.map((item, i) => {
              //               return (
              //                 <div className="col-12 col-md-4 col-lg">
              //                   <Feature
              //                     img={item.icon && item.icon.mediaItemUrl}
              //                     title={item.title && item.title}
              //                     titleClassName="mb-2 mt-sm-down-1"
              //                     text={item.description && item.description}
              //                     key={i}
              //                   />
              //                 </div>
              //               )
              //             })}
              //         </div>
              //       </Container>
              //     </Section>
              //   )

              {
                /* case "acf/instagram":
            return (
              <Instagram
                title={block.instagram.title}
                bgColor={block.instagram.bgColor}
                className={block.attributes.className}
              />
            ); */
              }

            case "acf/cover-content":
              return (
                <>
                  <CoverContent
                    theme="dark"
                    img={
                      block.coverContent &&
                      block.coverContent.img &&
                      block.coverContent.img.sourceUrl
                    }
                    imgOverlay="20"
                    className={`mb-n2 ${block.coverContent.bgColor ||
                      ""} ${block.attributes.className || ""}`}
                  >
                    <h2 className="h2">{block.coverContent.title || ""}</h2>
                    <p>{block.coverContent.text || ""}</p>
                    {block.coverContent.buttons && (
                      <ButtonGroup>
                        {block.coverContent.buttons.length > 0 &&
                          block.coverContent.buttons.map((item, i) => {
                            return (
                              <Button
                                variant="link"
                                link={item.link && item.link.url}
                                target={item.link && item.link.target}
                                key={i}
                              >
                                {item.text}
                              </Button>
                            )
                          })}
                      </ButtonGroup>
                    )}
                  </CoverContent>
                </>
              )
            case "core/shortcode":
              return (
                <Shortcodes
                  data={block}
                  key={`${block.name}${i}`}
                  nearbyData={nearbyData}
                  eBrochure={eBrochure}
                  name={wp.title}
                  lang={lang}
                  // eCatalogUrl={() => {props?.setAttentionModal(true)}}
                  projectData={wp}
                  propertyTypesProject={propertyTypesName}
                  contentIds={contentIds}
                  thisUrl={eCatalogProject?.url?eCatalogProject?.url: "#"}
                />
              )
            case "acf/virtual-link":
              let links = []
              block.acfVirtualLink.cards.map(item => {
                links.push({
                  image: item.image && item.image.mediaItemUrl,
                  propertyName: "", //item.title,
                  description: item.description || null,
                  linkTo: item.link && item.link.url,
                  target: item.link && item.link.target,
                })
              })
              return (
                <Container
                  className="py-main px-sm-down-0 mb-md-3"
                  key={`virtual-link-${i}`}
                >
                  <h2 className="pb-3 px-sm-down-3">{block.acfVirtualLink.title}</h2>
                  <GliderVirtualLink data={links} />
                </Container>
              )
          }
        })}
      {lang === "ID" &&
        wpID === null &&
        wp.blocks.map((block, i) => {
          switch (block.name) {
            case "core/embed":
              return (
                <Cover
                  type={{ name: "core-embed/youtube" }}
                  theme="dark"
                  imgHeight="h-400px h-md-500px w-100"
                  imgOverlay="40"
                  className="cover cover-full-top"
                  videoSrc={block.attributes.url}
                  // imgOverlay="30"
                  // imgHeight="h-500px h-md-600px w-100"
                  // className="cover-full cover-main"
                />
              )
            case "acf/cover-main":
              return (
                <>
                  <AcfCoverMain
                    data={block}
                    key={`${block.name}${i}`}
                    breadcrumb={location.pathname}
                  />
                  <section
                    className="py-main pt-3 pb-3 bg-light social-dev-wrapper"
                  >
                    <div className="container">
                      <div
                        className="row"
                      >
                        <div className="col-lg-12 col-xl-12">
                          <SocialShare link={thisUrl} onClick={handleCopy}/>
                        </div>
                      </div>
                    </div>
                  </section>
                  <div>
                    <meta itemProp="mpn" content="925872" />
                    <meta itemProp="name" content={seo?.title} />
                    <link itemProp="image" href={block?.coverMain?.img?.mediaItemUrl} />
                    <meta itemProp="description" content={seo?.opengraphDescription} />
                    <div itemProp="offers" itemType="https://schema.org/AggregateOffer" itemScope>
                      <meta itemProp="lowPrice" content="0" />
                      <meta itemProp="highPrice" content="0" />
                      <meta itemProp="offerCount" content="10" />
                      <meta itemProp="priceCurrency" content="IDR" />
                    </div>
                    <div itemProp="review" itemType="https://schema.org/Review" itemScope>
                      <div itemProp="author" itemType="https://schema.org/Person" itemScope>
                        <meta itemProp="name" content="Sinar Mas Land" />
                      </div>
                      <div itemProp="reviewRating" itemType="https://schema.org/Rating" itemScope>
                        <meta itemProp="ratingValue" content="4.5" />
                        <meta itemProp="bestRating" content="5" />
                      </div>
                    </div>
                    <meta itemProp="sku" content="0446310786" />
                    <div itemProp="brand" itemType="https://schema.org/Brand" itemScope>
                      <meta itemProp="name" content="BSD City" />
                    </div>
                  </div>
                </>
              )

            case "acf/col-headline":
              return (
                <Section className="bg-light">
                  <Container className="">
                    <div className="row">
                      <div className="col-lg-6 col-xl-5 pr-4">
                        <h2 className="h2">
                          {block.colHeadline &&
                            block.colHeadline.title &&
                            replaceUnicode(block.colHeadline.title)}
                        </h2>
                      </div>
                      <div className="col-lg-6 col-xl-7">
                        <p>
                          {
                            // block.colHeadline && block.colHeadline.text
                            <ReactMarkdown
                              source={block.colHeadline.text}
                              escapeHtml={false}
                            />
                          }
                        </p>
                        {/* On FUTURE DEVELOPMENT details pages only */}
                        {block.colHeadline.additionalInfo != null ? (
                          <>
                            <div className="mt-5">
                              <p class="h4 mb-3 text-gray-600">
                                {block.colHeadline.additionalInfo[0].label}
                              </p>
                              <p>{block.colHeadline.additionalInfo[0].value}</p>
                            </div>
                            {block.colHeadline.buttons != null ? (
                              <Button
                                variant="link"
                                to={block.colHeadline.buttons[0].url}
                              >
                                {block.colHeadline.buttons[0].text}
                              </Button>
                            ) : null}
                          </>
                        ) : null}
                      </div>
                    </div>
                  </Container>
                </Section>
              )

            case "acf/image-slider":
              if (block.imageSlider && block.imageSlider.images !== null) {
                return (
                  <Section>
                    <Container>
                      <HeadingLink>
                        <h2 className="h3">
                          {block.imageSlider && block.imageSlider.title}
                        </h2>
                        <Button variant="link">
                          {block.imageSlider && block.imageSlider.link.text}
                        </Button>
                      </HeadingLink>
                      <CardSlider
                        showInitial={6}
                        showLgDown={5}
                        showMdDown={4}
                        showSmDown={3.5}
                      >
                        {block.imageSlider &&
                          block.imageSlider.images.length > 0 &&
                          block.imageSlider.images.map((item, i) => {
                            return (
                              <Card
                                variant="boxless"
                                img={item.img && item.img.mediaItemUrl}
                                imgHeight="h-ratio-1-1"
                              />
                            )
                          })}
                      </CardSlider>
                    </Container>
                  </Section>
                )
              }

            case "acf/acfgallery":
              return (
                <Section
                  className={`${block.acfBlockGallery &&
                    block.acfBlockGallery.bgColor} ${block.attributes &&
                    block.attributes.className}`}
                >
                  <Container className="px-sm-down-0">
                    <CardSlider
                      // fade
                      noGutter
                      showInitial={1}
                      showLgDown={1}
                      showMdDown={1}
                      showSmDown={1}
                      visibleInitial={false}
                      visibleLgDown={false}
                      arrowsLgDown={true}
                      arrowsMdDown={true}
                      arrowsSmDown={true}
                    >
                      {block.acfBlockGallery &&
                        block.acfBlockGallery.images &&
                        block.acfBlockGallery.images.map((item, i) => {
                          return (
                            <Card
                              variant="boxless"
                              img={item.sourceUrl}
                              imgHeight="h-ratio-2-1"
                              key={i}
                            />
                          )
                        })}
                    </CardSlider>
                  </Container>
                </Section>
              )

            case "acf/map-with-features":
              return (
                <>
                  <Section className="map-w-features p-0">
                    <div className="row row-0">
                      <div className="col-md-7">
                        <GoogleMapsEmbed
                          embedUrl={wp?.embedProjectsLocation?.embedUrl}
                        />
                        {/* <Map
                          long={
                            block.MapWFeatures.location &&
                            parseFloat(block.MapWFeatures.location.longitude)
                          }
                          lat={
                            block.MapWFeatures.location &&
                            parseFloat(block.MapWFeatures.location.latitude)
                          }
                          zoom={16}
                        /> */}
                      </div>
                      <div className="col-md-5 py-main-sm px-md-4 bg-secondary">
                        <Container className="features-top">
                          <h2 className="h2">
                            {block.MapWFeatures && block.MapWFeatures.title}
                          </h2>
                          <ul>
                            {block.MapWFeatures.featuresTop &&
                              block.MapWFeatures.featuresTop.map((item, i) => {
                                return (
                                  <li key={i}>
                                    <Feature
                                      title={item.name}
                                      text={item.text}
                                    />
                                  </li>
                                )
                              })}
                          </ul>
                        </Container>
                      </div>
                    </div>
                  </Section>
                  <Section>
                    <Container className="features-bottom">
                      <div className="row row-3">
                        {block.MapWFeatures.featuresBottom &&
                          block.MapWFeatures.featuresBottom.length > 0 &&
                          block.MapWFeatures.featuresBottom.map((item, i) => {
                            return (
                              <div className="col-md-6" key={i}>
                                <Feature title={item.name} text={item.text} />
                              </div>
                            )
                          })}
                      </div>
                    </Container>
                  </Section>
                </>
              )

            case "acf/iconlist":
              return (
                <Section
                  className={`${block.acfBlockIconList &&
                    block.acfBlockIconList.bgColor} ${block.acfBlockIconList &&
                    block.attributes.className}`}
                >
                  <Container>
                    <div className="mb-4">
                      <h2 className="h2">
                        {block.acfBlockIconList && block.acfBlockIconList.title}
                      </h2>
                      <p className="mb-0">
                        {block.acfBlockIconList && block.acfBlockIconList.text}
                      </p>
                    </div>
                    <div className="row mb-n2">
                      {block?.acfBlockIconList?.list &&
                        block?.acfBlockIconList?.list.length > 0 &&
                        block?.acfBlockIconList?.list.map((item, i) => {
                          return (
                            <div className="col-12 col-md-4 col-lg">
                              <Feature
                                img={item.icon && item.icon.mediaItemUrl}
                                title={item.title && item.title}
                                titleClassName="mb-2 mt-sm-down-1"
                                text={item.description && item.description}
                                key={i}
                              />
                            </div>
                          )
                        })}
                    </div>
                  </Container>
                </Section>
              )

              // case "acf/iconlist":
              //   const iconListBlock = iconList?.project?.blocks.find(
              //     item => item.name === "acf/iconlist"
              //   )
              //   return (
              //     <Section
              //       className={`${block.acfBlockIconList &&
              //         block.acfBlockIconList.bgColor} ${block.acfBlockIconList &&
              //         block.attributes.className}`}
              //     >
              //       <Container>
              //         <div className="mb-4">
              //           <h2 className="h2">
              //             {block.acfBlockIconList && block.acfBlockIconList.title}
              //           </h2>
              //           <p className="mb-0">
              //             {block.acfBlockIconList && block.acfBlockIconList.text}
              //           </p>
              //         </div>
              //         <div className="row mb-n2">
              //           {iconListBlock?.acfBlockIconList?.list &&
              //             iconListBlock?.acfBlockIconList?.list.length > 0 &&
              //             iconListBlock?.acfBlockIconList?.list.map((item, i) => {
              //               return (
              //                 <div className="col-12 col-md-4 col-lg">
              //                   <Feature
              //                     img={item.icon && item.icon.mediaItemUrl}
              //                     title={item.title && item.title}
              //                     titleClassName="mb-2 mt-sm-down-1"
              //                     text={item.description && item.description}
              //                     key={i}
              //                   />
              //                 </div>
              //               )
              //             })}
              //         </div>
              //       </Container>
              //     </Section>
              //   )

              {
                /* case "acf/instagram":
            return (
              <Instagram
                title={block.instagram.title}
                bgColor={block.instagram.bgColor}
                className={block.attributes.className}
              />
            ); */
              }

            case "acf/cover-content":
              return (
                <>
                  <CoverContent
                    theme="dark"
                    img={
                      block.coverContent &&
                      block.coverContent.img &&
                      block.coverContent.img.sourceUrl
                    }
                    imgOverlay="20"
                    className={`mb-n2 ${block.coverContent.bgColor ||
                      ""} ${block.attributes.className || ""}`}
                  >
                    <h2 className="h2">{block.coverContent.title || ""}</h2>
                    <p>{block.coverContent.text || ""}</p>
                    {block.coverContent.buttons && (
                      <ButtonGroup>
                        {block.coverContent.buttons.length > 0 &&
                          block.coverContent.buttons.map((item, i) => {
                            return (
                              <Button
                                variant="link"
                                link={item.link && item.link.url}
                                target={item.link && item.link.target}
                                key={i}
                              >
                                {item.text}
                              </Button>
                            )
                          })}
                      </ButtonGroup>
                    )}
                  </CoverContent>
                </>
              )
            case "core/shortcode":
              return (
                <Shortcodes
                  data={block}
                  key={`${block.name}${i}`}
                  nearbyData={nearbyData}
                  eBrochure={eBrochure}
                  name={wp.title}
                  lang={lang}
                  // eCatalogUrl={() => {props?.setAttentionModal(true)}}
                  projectData={wp}
                  propertyTypesProject={propertyTypesName}
                  contentIds={contentIds}
                  thisUrl={eCatalogProject?.url?eCatalogProject?.url: "#"}
                />
              )
            case "acf/virtual-link":
              let links = []
              block.acfVirtualLink.cards.map(item => {
                links.push({
                  image: item.image && item.image.mediaItemUrl,
                  propertyName: "", //item.title,
                  description: item.description || null,
                  linkTo: item.link && item.link.url,
                  target: item.link && item.link.target,
                })
              })
              return (
                <Container
                  className="py-main px-sm-down-0 mb-md-3"
                  key={`virtual-link-${i}`}
                >
                  <h2 className="pb-3 px-sm-down-3">{block.acfVirtualLink.title}</h2>
                  <GliderVirtualLink data={links} />
                </Container>
              )
          }
        })}

      {lang === "EN" &&
        wp &&
        wp.blocks.map((block, i) => {
          switch (block.name) {
            case "core/embed":
              return (
                <Cover
                  type={{ name: "core-embed/youtube" }}
                  theme="dark"
                  imgHeight="h-400px h-md-500px w-100"
                  imgOverlay="40"
                  className="cover cover-full-top"
                  videoSrc={block.attributes.url}
                  // imgOverlay="30"
                  // imgHeight="h-500px h-md-600px w-100"
                  // className="cover-full cover-main"
                />
              )
            case "acf/cover-main":
              return (
                <>
                  <AcfCoverMain
                    data={block}
                    key={`${block.name}${i}`}
                    breadcrumb={location.pathname}
                  />
                  <section
                    className="py-main pt-3 pb-3 bg-light social-dev-wrapper"
                  >
                    <div className="container">
                      <div
                        className="row"
                      >
                        <div className="col-lg-12 col-xl-12">
                          <SocialShare link={thisUrl} onClick={handleCopy}/>
                        </div>
                      </div>
                    </div>
                  </section>
                  <div>
                    <meta itemProp="mpn" content="925872" />
                    <meta itemProp="name" content={seo?.title} />
                    <link itemProp="image" href={block?.coverMain?.img?.mediaItemUrl} />
                    <meta itemProp="description" content={seo?.opengraphDescription} />
                    <div itemProp="offers" itemType="https://schema.org/AggregateOffer" itemScope>
                      <meta itemProp="lowPrice" content="0" />
                      <meta itemProp="highPrice" content="0" />
                      <meta itemProp="offerCount" content="10" />
                      <meta itemProp="priceCurrency" content="IDR" />
                    </div>
                    <div itemProp="review" itemType="https://schema.org/Review" itemScope>
                      <div itemProp="author" itemType="https://schema.org/Person" itemScope>
                        <meta itemProp="name" content="Sinar Mas Land" />
                      </div>
                      <div itemProp="reviewRating" itemType="https://schema.org/Rating" itemScope>
                        <meta itemProp="ratingValue" content="4.5" />
                        <meta itemProp="bestRating" content="5" />
                      </div>
                    </div>
                    <meta itemProp="sku" content="0446310786" />
                    <div itemProp="brand" itemType="https://schema.org/Brand" itemScope>
                      <meta itemProp="name" content="BSD City" />
                    </div>
                  </div>
                </>
              )

            case "acf/col-headline":
              return (
                <Section className="bg-light">
                  <Container className="">
                    <div className="row">
                      <div className="col-lg-6 col-xl-5 pr-4">
                        <h2 className="h2">
                          {block.colHeadline &&
                            block.colHeadline.title &&
                            replaceUnicode(block.colHeadline.title)}
                        </h2>
                      </div>
                      <div className="col-lg-6 col-xl-7">
                        <p>
                          {
                            // block.colHeadline && block.colHeadline.text
                            <ReactMarkdown
                              source={block.colHeadline.text}
                              escapeHtml={false}
                            />
                          }
                        </p>
                        {/* On FUTURE DEVELOPMENT details pages only */}
                        {block.colHeadline.additionalInfo != null ? (
                          <>
                            <div className="mt-5">
                              <p class="h4 mb-3 text-gray-600">
                                {block.colHeadline.additionalInfo[0].label}
                              </p>
                              <p>{block.colHeadline.additionalInfo[0].value}</p>
                            </div>
                            {block.colHeadline.buttons != null ? (
                              <Button
                                variant="link"
                                to={block.colHeadline.buttons[0].url}
                              >
                                {block.colHeadline.buttons[0].text}
                              </Button>
                            ) : null}
                          </>
                        ) : null}
                      </div>
                    </div>
                  </Container>
                </Section>
              )

            case "acf/image-slider":
              if (block.imageSlider && block.imageSlider.images !== null) {
                return (
                  <Section>
                    <Container>
                      <HeadingLink>
                        <h2 className="h3">
                          {block.imageSlider && block.imageSlider.title}
                        </h2>
                        <Button variant="link">
                          {block.imageSlider && block.imageSlider.link.text}
                        </Button>
                      </HeadingLink>
                      <CardSlider
                        showInitial={6}
                        showLgDown={5}
                        showMdDown={4}
                        showSmDown={3.5}
                      >
                        {block.imageSlider &&
                          block.imageSlider.images.length > 0 &&
                          block.imageSlider.images.map((item, i) => {
                            return (
                              <Card
                                variant="boxless"
                                img={item.img && item.img.mediaItemUrl}
                                imgHeight="h-ratio-1-1"
                              />
                            )
                          })}
                      </CardSlider>
                    </Container>
                  </Section>
                )
              }

            case "acf/acfgallery":
              return (
                <Section
                  className={`${block.acfBlockGallery &&
                    block.acfBlockGallery.bgColor} ${block.attributes &&
                    block.attributes.className}`}
                >
                  <Container className="px-sm-down-0">
                    <CardSlider
                      // fade
                      noGutter
                      showInitial={1}
                      showLgDown={1}
                      showMdDown={1}
                      showSmDown={1}
                      visibleInitial={false}
                      visibleLgDown={false}
                      arrowsLgDown={true}
                      arrowsMdDown={true}
                      arrowsSmDown={true}
                    >
                      {block.acfBlockGallery &&
                        block.acfBlockGallery.images &&
                        block.acfBlockGallery.images.map((item, i) => {
                          return (
                            <Card
                              variant="boxless"
                              img={item.sourceUrl}
                              imgHeight="h-ratio-2-1"
                              key={i}
                            />
                          )
                        })}
                    </CardSlider>
                  </Container>
                </Section>
              )

            case "acf/map-with-features":
              return (
                <>
                  <Section className="map-w-features p-0">
                    <div className="row row-0">
                      <div className="col-md-7">
                        <GoogleMapsEmbed
                          embedUrl={wp?.embedProjectsLocation?.embedUrl}
                        />
                        {/* <Map
                          long={
                            block.MapWFeatures.location &&
                            parseFloat(block.MapWFeatures.location.longitude)
                          }
                          lat={
                            block.MapWFeatures.location &&
                            parseFloat(block.MapWFeatures.location.latitude)
                          }
                          zoom={16}
                        /> */}
                      </div>
                      <div className="col-md-5 py-main-sm px-md-4 bg-secondary">
                        <Container className="features-top">
                          <h2 className="h2">
                            {block.MapWFeatures && block.MapWFeatures.title}
                          </h2>
                          <ul>
                            {block.MapWFeatures.featuresTop &&
                              block.MapWFeatures.featuresTop.map((item, i) => {
                                return (
                                  <li key={i}>
                                    <Feature
                                      title={item.name}
                                      text={item.text}
                                    />
                                  </li>
                                )
                              })}
                          </ul>
                        </Container>
                      </div>
                    </div>
                  </Section>
                  <Section>
                    <Container className="features-bottom">
                      <div className="row row-3">
                        {block.MapWFeatures.featuresBottom &&
                          block.MapWFeatures.featuresBottom.length > 0 &&
                          block.MapWFeatures.featuresBottom.map((item, i) => {
                            return (
                              <div className="col-md-6" key={i}>
                                <Feature title={item.name} text={item.text} />
                              </div>
                            )
                          })}
                      </div>
                    </Container>
                  </Section>
                </>
              )

            case "acf/iconlist":
              return (
                <Section
                  className={`${block.acfBlockIconList &&
                    block.acfBlockIconList.bgColor} ${block.acfBlockIconList &&
                    block.attributes.className}`}
                >
                  <Container>
                    <div className="mb-4">
                      <h2 className="h2">
                        {block.acfBlockIconList && block.acfBlockIconList.title}
                      </h2>
                      <p className="mb-0">
                        {block.acfBlockIconList && block.acfBlockIconList.text}
                      </p>
                    </div>
                    <div className="row mb-n2">
                      {block?.acfBlockIconList?.list &&
                        block?.acfBlockIconList?.list.length > 0 &&
                        block?.acfBlockIconList?.list.map((item, i) => {
                          return (
                            <div className="col-12 col-md-4 col-lg">
                              <Feature
                                img={item.icon && item.icon.mediaItemUrl}
                                title={item.title && item.title}
                                titleClassName="mb-2 mt-sm-down-1"
                                text={item.description && item.description}
                                key={i}
                              />
                            </div>
                          )
                        })}
                    </div>
                  </Container>
                </Section>
              )

              // case "acf/iconlist":
              //   const iconListBlock = iconList?.project?.blocks.find(
              //     item => item.name === "acf/iconlist"
              //   )
              //   return (
              //     <Section
              //       className={`${block.acfBlockIconList &&
              //         block.acfBlockIconList.bgColor} ${block.acfBlockIconList &&
              //         block.attributes.className}`}
              //     >
              //       <Container>
              //         <div className="mb-4">
              //           <h2 className="h2">
              //             {block.acfBlockIconList && block.acfBlockIconList.title}
              //           </h2>
              //           <p className="mb-0">
              //             {block.acfBlockIconList && block.acfBlockIconList.text}
              //           </p>
              //         </div>
              //         <div className="row mb-n2">
              //           {iconListBlock?.acfBlockIconList?.list &&
              //             iconListBlock?.acfBlockIconList?.list.length > 0 &&
              //             iconListBlock?.acfBlockIconList?.list.map((item, i) => {
              //               return (
              //                 <div className="col-12 col-md-4 col-lg">
              //                   <Feature
              //                     img={item.icon && item.icon.mediaItemUrl}
              //                     title={item.title && item.title}
              //                     titleClassName="mb-2 mt-sm-down-1"
              //                     text={item.description && item.description}
              //                     key={i}
              //                   />
              //                 </div>
              //               )
              //             })}
              //         </div>
              //       </Container>
              //     </Section>
              //   )

              {
                /* case "acf/instagram":
              return (
                <Instagram
                  title={block.instagram.title}
                  bgColor={block.instagram.bgColor}
                  className={block.attributes.className}
                />
              ); */
              }

            case "acf/cover-content":
              return (
                <>
                  <CoverContent
                    theme="dark"
                    img={
                      block.coverContent &&
                      block.coverContent.img &&
                      block.coverContent.img.sourceUrl
                    }
                    imgOverlay="20"
                    className={`mb-n2 ${block.coverContent.bgColor ||
                      ""} ${block.attributes.className || ""}`}
                  >
                    <h2 className="h2">{block.coverContent.title || ""}</h2>
                    <p>{block.coverContent.text || ""}</p>
                    {block.coverContent.buttons && (
                      <ButtonGroup>
                        {block.coverContent.buttons.length > 0 &&
                          block.coverContent.buttons.map((item, i) => {
                            return (
                              <Button
                                variant="link"
                                link={item.link && item.link.url}
                                target={item.link && item.link.target}
                                key={i}
                              >
                                {item.text}
                              </Button>
                            )
                          })}
                      </ButtonGroup>
                    )}
                  </CoverContent>
                </>
              )
            case "core/shortcode":
              return (
                <Shortcodes
                  data={block}
                  key={`${block.name}${i}`}
                  nearbyData={nearbyData}
                  eBrochure={eBrochure}
                  name={wp.title}
                  lang={lang}
                  // eCatalogUrl={() => {props?.setAttentionModal(true)}}
                  projectData={wp}
                  propertyTypesProject={propertyTypesName}
                  contentIds={contentIds}
                  thisUrl={eCatalogProject?.url?eCatalogProject?.url: "#"}
                />
              )
            case "acf/virtual-link":
              let links = []
              block.acfVirtualLink && block.acfVirtualLink.cards && block.acfVirtualLink.cards.map(item => {
                links.push({
                  image: item.image && item.image.mediaItemUrl,
                  propertyName: "", //item.title,
                  description: item.description || null,
                  linkTo: item.link && item.link.url,
                  target: item.link && item.link.target,
                })
              })
              return (
                <Container
                  className="py-main px-sm-down-0 mb-md-3"
                  key={`virtual-link-${i}`}
                >
                  <h2 className="pb-3 px-sm-down-3">{block.acfVirtualLink.title}</h2>
                  <GliderVirtualLink data={links} />
                </Container>
              )
          }
        })}

      {/* {currentType && projectsByType && projectsByType.length > 2 && (
        <NavBottom
          data={projectsByType}
          slug={slug}
          prevTitle={prevProject() && prevProject().title}
          prevImg={
            (prevProject() &&
              prevProject().blocks.length > 0 &&
              prevProject().blocks[0].coverMain.img &&
              prevProject().blocks[0].coverMain.img.mediaItemUrl) ||
            data.imgCover.childImageSharp.fluid
          }
          prevLink={`/development/${currentType}/${(prevProject() && prevProject().slug) ||
            ""}`}
          nextTitle={nextProject() && nextProject().title}
          nextImg={
            (nextProject() &&
              nextProject().blocks.length > 0 &&
              nextProject().blocks[0].coverMain.img &&
              nextProject().blocks[0].coverMain.img.mediaItemUrl) ||
            data.imgCover.childImageSharp.fluid
          }
          nextLink={`/development/${currentType}/${(nextProject() && nextProject().slug) ||
            ""}`}
        />
      )} */}
      {currentType && projectsByType && projectsByType.length > 2 && (
        <NavBottom
          lang={lang}
          data={projectsByType}
          slug={slug}
          prevTitle={prevProject() && prevProject().title}
          prevImg={
            (prevProject() &&
              prevProject().blocks.length > 0 &&
              prevProject()?.blocks[0]?.coverMain?.img &&
              prevProject()?.blocks[0]?.coverMain?.img?.mediaItemUrl) ||
            data?.imgCover?.childImageSharp?.fluid
          }
          prevLink={`/development/${currentType}/${(prevProject() &&
            prevProject().slug) ||
            ""}`}
          nextTitle={nextProject() && nextProject().title}
          nextImg={
            (nextProject() &&
              nextProject()?.blocks?.length > 0 &&
              nextProject()?.blocks[0]?.coverMain?.img &&
              nextProject()?.blocks[0]?.coverMain?.img?.mediaItemUrl) ||
            data?.imgCover?.childImageSharp?.fluid
          }
          nextLink={`/development/${currentType}/${(nextProject() &&
            nextProject().slug) ||
            ""}`}
        />
      )}
    </Layout>
  )
}

export default DevelopmentDetails
