import React, { useState, useEffect, useContext } from "react"
import { useStaticQuery, graphql } from "gatsby"

import { Button } from "../buttons/buttons"
import { Container, Section } from "../grid/grid"
// import { HeadingBasic } from "../heading/heading"

import { LangContext } from "../../../context/LangContext"

const importNumberForm = () => import("react-number-format")


export const CalculatorFinance = () => {
  const { lang, setLang } = useContext(LangContext)
  const [NumberFormat, setNumberFormat] = useState()
  useEffect(() => {
    importNumberForm().then(component => setNumberFormat(component))
  }, [])
  const NFComponent = NumberFormat && NumberFormat.default

  const data = useStaticQuery(graphql`
    query {
      imgCover: file(relativePath: { eq: "sample/bg_rectangle-dark-2.jpg" }) {
        id
        name
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const [formValues, setFormValues] = useState({
    price: "",
    downPayment: "",
    loanTerm: "",
    interestRate: "",
  })
  const [result, setResult] = useState(0)

  const regex = /^[0-9,\b]+$/
  const pattern = /(\d+),(?=\d{3}(\D|$))/g

  const priceChange = e => {
    const unformatValues = e.target.value.replace(pattern, "$1")
    setFormValues({ ...formValues, price: parseInt(unformatValues) })
  }

  const downPaymentChange = e => {
    const unformatValues = e.target.value.replace(pattern, "$1")
    setFormValues({ ...formValues, downPayment: parseInt(unformatValues) })
  }

  const loanTermChange = e => {
    if (e.target.value === "" || regex.test(e.target.value)) {
      setFormValues({ ...formValues, loanTerm: parseInt(e.target.value) })
    }
  }

  const interestRateChange = e => {
    if (e.target.value === "" || regex.test(e.target.value)) {
      setFormValues({ ...formValues, interestRate: parseInt(e.target.value) })
    }
  }

  const disableButton = () => {
    if (
      formValues.price !== "" &&
      formValues.downPayment !== "" &&
      formValues.loanTerm !== "" &&
      formValues.interestRate !== ""
    ) {
      return false
    } else {
      return true
    }
  }

  const calculateMortgage = e => {
    e.preventDefault()
    const totalLoan = formValues.price - formValues.downPayment
    const loanTermInMonths = formValues.loanTerm * 12
    const tenor = totalLoan / loanTermInMonths
    const totalInstallments = tenor * (formValues.interestRate / 100) + tenor

    let x = totalInstallments.toFixed(2).toString()
    const pattern = /(-?\d+)(\d{3})/
    while (pattern.test(x)) x = x.replace(pattern, "$1,$2")

    setResult(x)
  }
  const resetResult = () => setResult(0)

  return (
    <div className="calculator-finance">
      <div
        className={`cover-bg`}
        style={{
          backgroundImage: `url(${require("../../../assets/img/bg/finance-bg.png")})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <div className="overlay"></div>
        <Container>
        <div className="row">
          <div className="col-md-6">
            <div className="mb-5 d-block d-md-none">
              <h2 className="">{lang == "EN" ? "Make it yours with financing." : "Hitung anggaran untuk properti ini."}</h2>
              <p>{lang == "EN" ? "Get an estimate of your monthly installments and make this property yours!" : "Dapatkan perkiraan cicilan bulanan dan jadikan property ini milik Anda!"}</p>
            </div>
            <form>
              <div className="form-group w-label-left">
                <span className="label">Rp</span>
                {NFComponent && (
                  <NFComponent
                    className="form-control"
                    placeholder={lang == "EN" ? "Loan amount/property price" : "Masukan jumlah Pinjaman/Harga Properti"}
                    allowEmptyFormatting
                    thousandSeparator={true}
                    onChange={priceChange}
                  />
                )}
              </div>
              <div className="form-group w-label-left">
                <span className="label">Rp</span>
                 {NFComponent && (
                  <NFComponent
                    className="form-control"
                    placeholder={lang == "EN" ? "Down payment" : "Masukan jumlah uang muka"}
                    allowEmptyFormatting
                    thousandSeparator={true}
                    onChange={downPaymentChange}
                  />
                )}
              </div>
              <div className="form-row">
                <div className="col-md-6">
                  <div className="form-group w-label-right">
                      <span className="label">{lang == "EN" ? "Years" : "Tahun"}</span>
                       {NFComponent && (
                        <NFComponent
                          className="form-control"
                          placeholder={lang == "EN" ? "Loan terms" : "Masukan istilah Pinjaman"}
                          allowEmptyFormatting
                          onChange={loanTermChange}
                        />
                      )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group w-label-right">
                    <span className="label">%</span>
                     {NFComponent && (
                        <NFComponent
                          className="form-control"
                          placeholder={lang == "EN" ? "Interest rate" : "Masukan Suku Bunga"}
                          allowEmptyFormatting
                          onChange={interestRateChange}
                        />
                      )}
                  </div>
                </div>
              </div>
              <div className="form-group">
                <Button
                  variant="secondary"
                  className="btn-block align-self-center"
                  onClick={calculateMortgage}
                  disabled={disableButton()}
                >
                  {lang == "EN" ? "Calculate" : "Hitung"} <i className="fas fa-arrow-right ml-1"></i>
                </Button>
              </div>
            </form>
            <div className={`calculator-result d-block d-md-none ${result !== 0 ? "show" : "hide"}`}>
                <i class="fal fa-times" onClick={resetResult}></i>
                <span className="label">{lang == "EN" ? "Estimated monthly installments" : "Estimasi Cicilan Bulanan"}</span>
                <h3 className="installments">Rp{result}</h3>
            </div>
            <span>
              {lang == "EN" ? "This calculation is an estimate and not a depiction of actual payment plan" : "Perhitungan ini merupakan perkiraan dan bukan gambaran dari rencana pembayaran sebenarnya"}
            </span>
          </div>
          <div className="col-md-6">
            <div className="form-result d-none d-md-block">
              <h2 className="">{lang == "EN" ? "Make it yours with financing." : "Hitung anggaran untuk properti ini."}</h2>
              <p>{lang == "EN" ? "Get an estimate of your monthly installments and make this property yours!" : "Dapatkan perkiraan cicilan bulanan dan jadikan property ini milik Anda!"}</p>
              {/* <div className={`calculator-result ${result == 0 && close == null ? "d-none" : "d-show"} ${close && "d-none"}`}>
                  <i class="fal fa-times" onClick={handleClose}></i>
                  <span className="label">Estimated monthly installments</span>
                  <h3 className="installments">Rp{result}</h3>
              </div> */}
              <div className={`calculator-result ${result !== 0 ? "show" : "hide"}`}>
                  <i class="fal fa-times" onClick={resetResult}></i>
                  <span className="label">{lang == "EN" ? "Estimated monthly installments" : "Estimasi Cicilan Bulanan"}</span>
                  <h3 className="installments">Rp{result}</h3>
              </div>
            </div>
          </div>
        </div>
      </Container>
      </div>
    </div>
  )
}

